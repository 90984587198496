export const getToken = (): any => {
    const token = document.getElementById('token') as HTMLInputElement

    let value = null

    if (token !== null || token !== undefined) {
        if (token.value !== null) {
            value = token.value
        }
    }

    return value
}
