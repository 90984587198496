export const getAudio = async (): Promise<any> => {
    const AudioContext = window.OfflineAudioContext ?? window.webkitOfflineAudioContext
    const context = new AudioContext(1, 5000, 44100)

    const oscillator = context.createOscillator()
    oscillator.type = 'triangle'
    oscillator.frequency.value = 1000

    const compressor = context.createDynamicsCompressor()
    compressor.threshold.value = -50
    compressor.knee.value = 40
    compressor.ratio.value = 12
    compressor.attack.value = 0
    compressor.release.value = 0.2

    oscillator.connect(compressor)
    compressor.connect(context.destination)

    oscillator.start()

    const resultPromise = new Promise((resolve) => {
        context.oncomplete = event => {
            // We have only one channel, so we get it by index
            resolve({ a: calculateHash(event.renderedBuffer.getChannelData(0)) })
        }

        context.startRendering().catch((err) => {
            console.log(err)
        })
    })

    return await resultPromise
}

function calculateHash (samples: any): number {
    let hash = 0
    for (let i = 0; i < samples.length; ++i) {
        hash += Math.abs(samples[i])
    }
    return hash
}
