import { getGeneral } from './components/general'
import { getFonts } from './components/fonts'
import { getWebglInfo, getDomBlockers } from './components/visual'
import { getAudio } from './components/audio'
import { getToken } from './components/token'
import { getPlugins } from './components/plugins'
import { getMathathFingerprint } from './components/math'
import { getApplePayState } from './components/apple_pay'
import { isTouchable } from './components/touch'
// import { serialize } from '../utility/formHelpers'

document.addEventListener('DOMContentLoaded', () => {
    gatherInfo().catch(err => console.log(err))
})

async function gatherInfo (): Promise<any> {
    const data = await Promise.all([getGeneral(), getAudio(), getFonts(), getWebglInfo(), getDomBlockers(), getPlugins(), getMathathFingerprint(), getApplePayState(), isTouchable() ]).then(values => {
        let data: {[key: string]: string} = {}
        values.map(value => (
            data = { ...data, ...value as {} }
        ))

        let sortedFingerprint: {[key: string]: string} = {}

        sortedFingerprint = Object.keys(data)
            .sort().reduce((tempObj: {[key: string]: string}, key) => {
                tempObj[key] = data[key]
            return tempObj
        }, {})

        return sortedFingerprint
    })

    postInfo(data, getToken()).catch(err => console.log(err))
}

// function validateToken (token: any): boolean {
//     return /^[A-Za-z0-9]*$/.test(token)
// }

async function postInfo (data: {}, token: any): Promise<any> {
    await fetch('/buzz/bzz/', {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ json: data, token: token })
    })
}
