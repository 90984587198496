const isHDR = (): any =>
{
    if(matchMedia(`(dynamic-range: high)`).matches)
    {
        return true;
    } else if(matchMedia(`(dynamic-range: standard)`).matches)
    {
        return false;
    }
    return undefined;
}

const ssCheck = (): any =>
{
    try
    {
        return !!window.sessionStorage
    } catch (error) {
        /* SecurityError when referencing it means it exists */
        return true
    }
}

export const getGeneral = (): {} => {

    return {
        s_cD: window.screen.colorDepth,
        dM: navigator.deviceMemory,
        hCc: navigator.hardwareConcurrency,
        hdr: isHDR(),
        osCpu: navigator.oscpu ? navigator.oscpu : false,
        pVE: navigator.pdfViewerEnabled,
        sS: ssCheck(),
        u_a: navigator.userAgent, //user_agent
        t_z_o: new Date().getTimezoneOffset(), // time_zone_offset
        dnt: navigator.doNotTrack !== null, // do_not_track
        lang: navigator.language !== null ? navigator.language : null // language 
    }
}
