export const getWebglInfo = (): {} => {
    // let webglData = {};
    const canvas = document.createElement('canvas')
    canvas.hidden = true
    document.body.appendChild(canvas)
    const canvasElements = Array.from(document.getElementsByTagName('canvas'))
    // for testing purposes the canvas element is coded in the html
    // future js methods can create teh canvas element on the page
    if (canvasElements !== null && typeof (canvasElements) !== 'undefined') {
        const gl = canvasElements[0].getContext('webgl')
        const debugInfo = gl?.getExtension('WEBGL_debug_renderer_info')
        // webglData['vendor'] = debugInfo ? gl?.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) : null;
        // webglData['renderer'] = debugInfo ? gl?.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : null;
        // webglData['fingerprinting'] = webglFingerprinting(gl);
        // return webglData;

        return { 
            v: (debugInfo != null) ? gl?.getParameter(debugInfo.UNMASKED_VENDOR_WEBGL) : null, 
            r: (debugInfo != null) ? gl?.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL) : null, 
            webGL: webglFingerprinting(gl) 
        }
    }

    return {}
}

type Filters = Record<string, string[]>

// from amiunique repo
function webglFingerprinting (gl: any): any {
    const fa2s = function (fa: any): any {
        gl.clearColor(0.0, 0.0, 0.0, 1.0)
        gl.enable(gl.DEPTH_TEST)
        gl.depthFunc(gl.LEQUAL)
        gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT)
        return '[' + String(fa[0]) + ', ' + String(fa[1]) + ']'
    }
    const maxAnisotropy = function (gl: any): any {
        let anisotropy, ext
        //= gl.getExtension('EXT_texture_filter_anisotropic') || gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic') || gl.getExtension('MOZ_EXT_texture_filter_anisotropic')

        if (gl.getExtension('EXT_texture_filter_anisotropic') !== null) {
            ext = gl.getExtension('EXT_texture_filter_anisotropic')
        } else if (gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic') !== null) {
            ext = gl.getExtension('WEBKIT_EXT_texture_filter_anisotropic')
        } else if (gl.getExtension('MOZ_EXT_texture_filter_anisotropic') !== null) {
            ext = gl.getExtension('MOZ_EXT_texture_filter_anisotropic')
        } else {
            ext = null
        }
        anisotropy = Number(gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT))
        return ext !== null ? (Number(gl.getParameter(ext.MAX_TEXTURE_MAX_ANISOTROPY_EXT)), anisotropy === 0 && (anisotropy = 2), anisotropy) : null
    }
    const result = []
    const vShaderTemplate = 'attribute vec2 attrVertex;varying vec2 varyinTexCoordinate;uniform vec2 uniformOffset;void main(){varyinTexCoordinate=attrVertex+uniformOffset;gl_Position=vec4(attrVertex,0,1);}'
    const fShaderTemplate = 'precision mediump float;varying vec2 varyinTexCoordinate;void main() {gl_FragColor=vec4(varyinTexCoordinate,0,1);}'
    const vertexPosBuffer = gl.createBuffer()
    gl.bindBuffer(gl.ARRAY_BUFFER, vertexPosBuffer)
    const vertices = new Float32Array([-0.2, -0.9, 0, 0.4, -0.26, 0, 0, 0.732134444, 0])
    gl.bufferData(gl.ARRAY_BUFFER, vertices, gl.STATIC_DRAW)
    vertexPosBuffer.itemSize = 3
    vertexPosBuffer.numItems = 3
    const program = gl.createProgram(); const vshader = gl.createShader(gl.VERTEX_SHADER)
    gl.shaderSource(vshader, vShaderTemplate)
    gl.compileShader(vshader)
    const fshader = gl.createShader(gl.FRAGMENT_SHADER)
    gl.shaderSource(fshader, fShaderTemplate)
    gl.compileShader(fshader)
    gl.attachShader(program, vshader)
    gl.attachShader(program, fshader)
    gl.linkProgram(program)
    gl.useProgram(program)
    program.vertexPosAttrib = gl.getAttribLocation(program, 'attrVertex')
    program.offsetUniform = gl.getUniformLocation(program, 'uniformOffset')
    gl.enableVertexAttribArray(program.vertexPosArray)
    gl.vertexAttribPointer(program.vertexPosAttrib, vertexPosBuffer.itemSize, gl.FLOAT, false, 0, 0)
    gl.uniform2f(program.offsetUniform, 1, 1)
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, vertexPosBuffer.numItems)

    result.push('extensions:' + String(gl.getSupportedExtensions().join(';')))
    result.push('webgl aliased line width range:' + String(fa2s(gl.getParameter(gl.ALIASED_LINE_WIDTH_RANGE))))
    result.push('webgl aliased point size range:' + String(fa2s(gl.getParameter(gl.ALIASED_POINT_SIZE_RANGE))))
    result.push('webgl alpha bits:' + String(gl.getParameter(gl.ALPHA_BITS)))
    result.push('webgl antialiasing:' + (gl.getContextAttributes().antialias !== null ? 'yes' : 'no'))
    result.push('webgl blue bits:' + String(gl.getParameter(gl.BLUE_BITS)))
    result.push('webgl depth bits:' + String(gl.getParameter(gl.DEPTH_BITS)))
    result.push('webgl green bits:' + String(gl.getParameter(gl.GREEN_BITS)))
    result.push('webgl max anisotropy:' + String(maxAnisotropy(gl)))
    result.push('webgl max combined texture image units:' + String(gl.getParameter(gl.MAX_COMBINED_TEXTURE_IMAGE_UNITS)))
    result.push('webgl max cube map texture size:' + String(gl.getParameter(gl.MAX_CUBE_MAP_TEXTURE_SIZE)))
    result.push('webgl max fragment uniform vectors:' + String(gl.getParameter(gl.MAX_FRAGMENT_UNIFORM_VECTORS)))
    result.push('webgl max render buffer size:' + String(gl.getParameter(gl.MAX_RENDERBUFFER_SIZE)))
    result.push('webgl max texture image units:' + String(gl.getParameter(gl.MAX_TEXTURE_IMAGE_UNITS)))
    result.push('webgl max texture size:' + String(gl.getParameter(gl.MAX_TEXTURE_SIZE)))
    result.push('webgl max varying vectors:' + String(gl.getParameter(gl.MAX_VARYING_VECTORS)))
    result.push('webgl max vertex attribs:' + String(gl.getParameter(gl.MAX_VERTEX_ATTRIBS)))
    result.push('webgl max vertex texture image units:' + String(gl.getParameter(gl.MAX_VERTEX_TEXTURE_IMAGE_UNITS)))
    result.push('webgl max vertex uniform vectors:' + String(gl.getParameter(gl.MAX_VERTEX_UNIFORM_VECTORS)))
    result.push('webgl max viewport dims:' + String(fa2s(gl.getParameter(gl.MAX_VIEWPORT_DIMS))))
    result.push('webgl red bits:' + String(gl.getParameter(gl.RED_BITS)))
    result.push('webgl renderer:' + String(gl.getParameter(gl.RENDERER)))
    result.push('webgl shading language version:' + String(gl.getParameter(gl.SHADING_LANGUAGE_VERSION)))
    result.push('webgl stencil bits:' + String(gl.getParameter(gl.STENCIL_BITS)))
    result.push('webgl vendor:' + String(gl.getParameter(gl.VENDOR)))
    result.push('webgl version:' + String(gl.getParameter(gl.VERSION)))
    result.push('webgl vertex shader high float precision:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).precision))
    result.push('webgl vertex shader high float precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMin))
    result.push('webgl vertex shader high float precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_FLOAT).rangeMax))
    result.push('webgl vertex shader medium float precision:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).precision))
    result.push('webgl vertex shader medium float precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMin))
    result.push('webgl vertex shader medium float precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_FLOAT).rangeMax))
    result.push('webgl vertex shader low float precision:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).precision))
    result.push('webgl vertex shader low float precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMin))
    result.push('webgl vertex shader low float precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_FLOAT).rangeMax))
    result.push('webgl fragment shader high float precision:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).precision))
    result.push('webgl fragment shader high float precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMin))
    result.push('webgl fragment shader high float precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_FLOAT).rangeMax))
    result.push('webgl fragment shader medium float precision:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT).precision))
    result.push('webgl fragment shader medium float precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT).rangeMin))
    result.push('webgl fragment shader medium float precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_FLOAT).rangeMax))
    result.push('webgl fragment shader low float precision:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).precision))
    result.push('webgl fragment shader low float precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMin))
    result.push('webgl fragment shader low float precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_FLOAT).rangeMax))
    result.push('webgl vertex shader high int precision:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).precision))
    result.push('webgl vertex shader high int precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMin))
    result.push('webgl vertex shader high int precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.HIGH_INT).rangeMax))
    result.push('webgl vertex shader medium int precision:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).precision))
    result.push('webgl vertex shader medium int precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMin))
    result.push('webgl vertex shader medium int precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.MEDIUM_INT).rangeMax))
    result.push('webgl vertex shader low int precision:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).precision))
    result.push('webgl vertex shader low int precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMin))
    result.push('webgl vertex shader low int precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.VERTEX_SHADER, gl.LOW_INT).rangeMax))
    result.push('webgl fragment shader high int precision:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).precision))
    result.push('webgl fragment shader high int precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMin))
    result.push('webgl fragment shader high int precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.HIGH_INT).rangeMax))
    result.push('webgl fragment shader medium int precision:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).precision))
    result.push('webgl fragment shader medium int precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMin))
    result.push('webgl fragment shader medium int precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.MEDIUM_INT).rangeMax))
    result.push('webgl fragment shader low int precision:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).precision))
    result.push('webgl fragment shader low int precision rangeMin:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMin))
    result.push('webgl fragment shader low int precision rangeMax:' + String(gl.getShaderPrecisionFormat(gl.FRAGMENT_SHADER, gl.LOW_INT).rangeMax))
    if (gl.canvas != null) { result.push(gl.canvas.toDataURL()) }
    return result
}

function countTruthy(values: unknown[]): number {
    return values.reduce<number>((sum, value) => sum + (value ? 1 : 0), 0)
}

// from fingerprintjs repo
export const getDomBlockers = async (): Promise<Record<string, any> | undefined> => {
    if (!isApplicable()) {
        return {
            domBlockers: {
                undefined
            }
        }
    }
  
    const filters = getFilters()
    const filterNames = Object.keys(filters) as Array<keyof typeof filters>
    const allSelectors = ([] as string[]).concat(...filterNames.map((filterName) => filters[filterName]))
    const blockedSelectors = await getBlockedSelectors(allSelectors)
  
    const activeBlockers = filterNames.filter((filterName) => {
      const selectors = filters[filterName]
      const blockedCount = countTruthy(selectors.map((selector) => blockedSelectors[selector]))
      return blockedCount > selectors.length * 0.6
    })

    activeBlockers.sort()


    return {
        domBlockers: {
            activeBlockers
        }
    }
}

function isApplicable(): boolean {
    // Safari (desktop and mobile) and all Android browsers keep content blockers in both regular and private mode
    return isWebKit() || isAndroid()
}

// from fingerprintjs repo
function isGecko(): boolean {
    const w = window
  
    // Based on research in September 2020
    return (
      countTruthy([
        'buildID' in navigator,
        'MozAppearance' in (document.documentElement?.style ?? {}),
        'onmozfullscreenchange' in w,
        'mozInnerScreenX' in w,
        'CSSMozDocumentRule' in w,
        'CanvasCaptureMediaStream' in w,
      ]) >= 4
    )
  }

  // from fingerprintjs repo
function isWebKit(): boolean {
    // Based on research in September 2020
    const w = window
    const n = navigator
  
    return (
      countTruthy([
        'ApplePayError' in w,
        'CSSPrimitiveValue' in w,
        'Counter' in w,
        n.vendor.indexOf('Apple') === 0,
        'getStorageUpdates' in n,
        'WebKitMediaKeys' in w,
      ]) >= 4
    )
 }

 // from fingerprintjs repo
 function isChromium(): boolean {
    // Based on research in October 2020. Tested to detect Chromium 42-86.
    const w = window
    const n = navigator
  
    return (
      countTruthy([
        'webkitPersistentStorage' in n,
        'webkitTemporaryStorage' in n,
        n.vendor.indexOf('Google') === 0,
        'webkitResolveLocalFileSystemURL' in w,
        'BatteryManager' in w,
        'webkitMediaStream' in w,
        'webkitSpeechGrammar' in w,
      ]) >= 5
    )
  }
// from fingerprintjs repo
function isAndroid(): boolean {
    const isItChromium = isChromium()
    const isItGecko = isGecko()
    const w = window
    const n = navigator
    const c = 'connection'
  
    // Chrome removes all words "Android" from `navigator` when desktop version is requested
    // Firefox keeps "Android" in `navigator.appVersion` when desktop version is requested
    if (isItChromium) {
      return (
        countTruthy([
          !('SharedWorker' in w),
          // `typechange` is deprecated, but it's still present on Android (tested on Chrome Mobile 117)
          // Removal proposal https://bugs.chromium.org/p/chromium/issues/detail?id=699892
          // Note: this expression returns true on ChromeOS, so additional detectors are required to avoid false-positives
          n.connection && 'ontypechange' in n.connection,
          !('sinkId' in new window.Audio()),
        ]) >= 2
      )
    } else if (isItGecko) {
      return countTruthy(['onorientationchange' in w, 'orientation' in w, /android/i.test(navigator.appVersion)]) >= 2
    } else {
      // Only 2 browser engines are presented on Android.
      // Actually, there is also Android 4.1 browser, but it's not worth detecting it at the moment.
      return false
    }
  }

// from fingerprintjs repo
function getFilters(): Filters {
    const fromB64 = atob // Just for better minification
    return {
      abpIndo: [
        '#Iklan-Melayang',
        '#Kolom-Iklan-728',
        '#SidebarIklan-wrapper',
        '[title="ALIENBOLA" i]',
        fromB64('I0JveC1CYW5uZXItYWRz'),
      ],
      abpvn: ['.quangcao', '#mobileCatfish', fromB64('LmNsb3NlLWFkcw=='), '[id^="bn_bottom_fixed_"]', '#pmadv'],
      adBlockFinland: [
        '.mainostila',
        fromB64('LnNwb25zb3JpdA=='),
        '.ylamainos',
        fromB64('YVtocmVmKj0iL2NsaWNrdGhyZ2guYXNwPyJd'),
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9hcHAucmVhZHBlYWsuY29tL2FkcyJd'),
      ],
      adBlockPersian: [
        '#navbar_notice_50',
        '.kadr',
        'TABLE[width="140px"]',
        '#divAgahi',
        fromB64('YVtocmVmXj0iaHR0cDovL2cxLnYuZndtcm0ubmV0L2FkLyJd'),
      ],
      adBlockWarningRemoval: [
        '#adblock-honeypot',
        '.adblocker-root',
        '.wp_adblock_detect',
        fromB64('LmhlYWRlci1ibG9ja2VkLWFk'),
        fromB64('I2FkX2Jsb2NrZXI='),
      ],
      adGuardAnnoyances: [
        '.hs-sosyal',
        '#cookieconsentdiv',
        'div[class^="app_gdpr"]',
        '.as-oil',
        '[data-cypress="soft-push-notification-modal"]',
      ],
      adGuardBase: [
        '.BetterJsPopOverlay',
        fromB64('I2FkXzMwMFgyNTA='),
        fromB64('I2Jhbm5lcmZsb2F0MjI='),
        fromB64('I2NhbXBhaWduLWJhbm5lcg=='),
        fromB64('I0FkLUNvbnRlbnQ='),
      ],
      adGuardChinese: [
        fromB64('LlppX2FkX2FfSA=='),
        fromB64('YVtocmVmKj0iLmh0aGJldDM0LmNvbSJd'),
        '#widget-quan',
        fromB64('YVtocmVmKj0iLzg0OTkyMDIwLnh5eiJd'),
        fromB64('YVtocmVmKj0iLjE5NTZobC5jb20vIl0='),
      ],
      adGuardFrench: [
        '#pavePub',
        fromB64('LmFkLWRlc2t0b3AtcmVjdGFuZ2xl'),
        '.mobile_adhesion',
        '.widgetadv',
        fromB64('LmFkc19iYW4='),
      ],
      adGuardGerman: ['aside[data-portal-id="leaderboard"]'],
      adGuardJapanese: [
        '#kauli_yad_1',
        fromB64('YVtocmVmXj0iaHR0cDovL2FkMi50cmFmZmljZ2F0ZS5uZXQvIl0='),
        fromB64('Ll9wb3BJbl9pbmZpbml0ZV9hZA=='),
        fromB64('LmFkZ29vZ2xl'),
        fromB64('Ll9faXNib29zdFJldHVybkFk'),
      ],
      adGuardMobile: [
        fromB64('YW1wLWF1dG8tYWRz'),
        fromB64('LmFtcF9hZA=='),
        'amp-embed[type="24smi"]',
        '#mgid_iframe1',
        fromB64('I2FkX2ludmlld19hcmVh'),
      ],
      adGuardRussian: [
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9hZC5sZXRtZWFkcy5jb20vIl0='),
        fromB64('LnJlY2xhbWE='),
        'div[id^="smi2adblock"]',
        fromB64('ZGl2W2lkXj0iQWRGb3hfYmFubmVyXyJd'),
        '#psyduckpockeball',
      ],
      adGuardSocial: [
        fromB64('YVtocmVmXj0iLy93d3cuc3R1bWJsZXVwb24uY29tL3N1Ym1pdD91cmw9Il0='),
        fromB64('YVtocmVmXj0iLy90ZWxlZ3JhbS5tZS9zaGFyZS91cmw/Il0='),
        '.etsy-tweet',
        '#inlineShare',
        '.popup-social',
      ],
      adGuardSpanishPortuguese: ['#barraPublicidade', '#Publicidade', '#publiEspecial', '#queTooltip', '.cnt-publi'],
      adGuardTrackingProtection: [
        '#qoo-counter',
        fromB64('YVtocmVmXj0iaHR0cDovL2NsaWNrLmhvdGxvZy5ydS8iXQ=='),
        fromB64('YVtocmVmXj0iaHR0cDovL2hpdGNvdW50ZXIucnUvdG9wL3N0YXQucGhwIl0='),
        fromB64('YVtocmVmXj0iaHR0cDovL3RvcC5tYWlsLnJ1L2p1bXAiXQ=='),
        '#top100counter',
      ],
      adGuardTurkish: [
        '#backkapat',
        fromB64('I3Jla2xhbWk='),
        fromB64('YVtocmVmXj0iaHR0cDovL2Fkc2Vydi5vbnRlay5jb20udHIvIl0='),
        fromB64('YVtocmVmXj0iaHR0cDovL2l6bGVuemkuY29tL2NhbXBhaWduLyJd'),
        fromB64('YVtocmVmXj0iaHR0cDovL3d3dy5pbnN0YWxsYWRzLm5ldC8iXQ=='),
      ],
      bulgarian: [fromB64('dGQjZnJlZW5ldF90YWJsZV9hZHM='), '#ea_intext_div', '.lapni-pop-over', '#xenium_hot_offers'],
      easyList: [
        '.yb-floorad',
        fromB64('LndpZGdldF9wb19hZHNfd2lkZ2V0'),
        fromB64('LnRyYWZmaWNqdW5reS1hZA=='),
        '.textad_headline',
        fromB64('LnNwb25zb3JlZC10ZXh0LWxpbmtz'),
      ],
      easyListChina: [
        fromB64('LmFwcGd1aWRlLXdyYXBbb25jbGljayo9ImJjZWJvcy5jb20iXQ=='),
        fromB64('LmZyb250cGFnZUFkdk0='),
        '#taotaole',
        '#aafoot.top_box',
        '.cfa_popup',
      ],
      easyListCookie: [
        '.ezmob-footer',
        '.cc-CookieWarning',
        '[data-cookie-number]',
        fromB64('LmF3LWNvb2tpZS1iYW5uZXI='),
        '.sygnal24-gdpr-modal-wrap',
      ],
      easyListCzechSlovak: [
        '#onlajny-stickers',
        fromB64('I3Jla2xhbW5pLWJveA=='),
        fromB64('LnJla2xhbWEtbWVnYWJvYXJk'),
        '.sklik',
        fromB64('W2lkXj0ic2tsaWtSZWtsYW1hIl0='),
      ],
      easyListDutch: [
        fromB64('I2FkdmVydGVudGll'),
        fromB64('I3ZpcEFkbWFya3RCYW5uZXJCbG9jaw=='),
        '.adstekst',
        fromB64('YVtocmVmXj0iaHR0cHM6Ly94bHR1YmUubmwvY2xpY2svIl0='),
        '#semilo-lrectangle',
      ],
      easyListGermany: [
        '#SSpotIMPopSlider',
        fromB64('LnNwb25zb3JsaW5rZ3J1ZW4='),
        fromB64('I3dlcmJ1bmdza3k='),
        fromB64('I3Jla2xhbWUtcmVjaHRzLW1pdHRl'),
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9iZDc0Mi5jb20vIl0='),
      ],
      easyListItaly: [
        fromB64('LmJveF9hZHZfYW5udW5jaQ=='),
        '.sb-box-pubbliredazionale',
        fromB64('YVtocmVmXj0iaHR0cDovL2FmZmlsaWF6aW9uaWFkcy5zbmFpLml0LyJd'),
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9hZHNlcnZlci5odG1sLml0LyJd'),
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9hZmZpbGlhemlvbmlhZHMuc25haS5pdC8iXQ=='),
      ],
      easyListLithuania: [
        fromB64('LnJla2xhbW9zX3RhcnBhcw=='),
        fromB64('LnJla2xhbW9zX251b3JvZG9z'),
        fromB64('aW1nW2FsdD0iUmVrbGFtaW5pcyBza3lkZWxpcyJd'),
        fromB64('aW1nW2FsdD0iRGVkaWt1b3RpLmx0IHNlcnZlcmlhaSJd'),
        fromB64('aW1nW2FsdD0iSG9zdGluZ2FzIFNlcnZlcmlhaS5sdCJd'),
      ],
      estonian: [fromB64('QVtocmVmKj0iaHR0cDovL3BheTRyZXN1bHRzMjQuZXUiXQ==')],
      fanboyAnnoyances: ['#ac-lre-player', '.navigate-to-top', '#subscribe_popup', '.newsletter_holder', '#back-top'],
      fanboyAntiFacebook: ['.util-bar-module-firefly-visible'],
      fanboyEnhancedTrackers: [
        '.open.pushModal',
        '#issuem-leaky-paywall-articles-zero-remaining-nag',
        '#sovrn_container',
        'div[class$="-hide"][zoompage-fontsize][style="display: block;"]',
        '.BlockNag__Card',
      ],
      fanboySocial: ['#FollowUs', '#meteored_share', '#social_follow', '.article-sharer', '.community__social-desc'],
      frellwitSwedish: [
        fromB64('YVtocmVmKj0iY2FzaW5vcHJvLnNlIl1bdGFyZ2V0PSJfYmxhbmsiXQ=='),
        fromB64('YVtocmVmKj0iZG9rdG9yLXNlLm9uZWxpbmsubWUiXQ=='),
        'article.category-samarbete',
        fromB64('ZGl2LmhvbGlkQWRz'),
        'ul.adsmodern',
      ],
      greekAdBlock: [
        fromB64('QVtocmVmKj0iYWRtYW4ub3RlbmV0LmdyL2NsaWNrPyJd'),
        fromB64('QVtocmVmKj0iaHR0cDovL2F4aWFiYW5uZXJzLmV4b2R1cy5nci8iXQ=='),
        fromB64('QVtocmVmKj0iaHR0cDovL2ludGVyYWN0aXZlLmZvcnRobmV0LmdyL2NsaWNrPyJd'),
        'DIV.agores300',
        'TABLE.advright',
      ],
      hungarian: [
        '#cemp_doboz',
        '.optimonk-iframe-container',
        fromB64('LmFkX19tYWlu'),
        fromB64('W2NsYXNzKj0iR29vZ2xlQWRzIl0='),
        '#hirdetesek_box',
      ],
      iDontCareAboutCookies: [
        '.alert-info[data-block-track*="CookieNotice"]',
        '.ModuleTemplateCookieIndicator',
        '.o--cookies--container',
        '#cookies-policy-sticky',
        '#stickyCookieBar',
      ],
      icelandicAbp: [fromB64('QVtocmVmXj0iL2ZyYW1ld29yay9yZXNvdXJjZXMvZm9ybXMvYWRzLmFzcHgiXQ==')],
      latvian: [
        fromB64(
          'YVtocmVmPSJodHRwOi8vd3d3LnNhbGlkemluaS5sdi8iXVtzdHlsZT0iZGlzcGxheTogYmxvY2s7IHdpZHRoOiAxMjBweDsgaGVpZ2h0O' +
            'iA0MHB4OyBvdmVyZmxvdzogaGlkZGVuOyBwb3NpdGlvbjogcmVsYXRpdmU7Il0=',
        ),
        fromB64(
          'YVtocmVmPSJodHRwOi8vd3d3LnNhbGlkemluaS5sdi8iXVtzdHlsZT0iZGlzcGxheTogYmxvY2s7IHdpZHRoOiA4OHB4OyBoZWlnaHQ6I' +
            'DMxcHg7IG92ZXJmbG93OiBoaWRkZW47IHBvc2l0aW9uOiByZWxhdGl2ZTsiXQ==',
        ),
      ],
      listKr: [
        fromB64('YVtocmVmKj0iLy9hZC5wbGFuYnBsdXMuY28ua3IvIl0='),
        fromB64('I2xpdmVyZUFkV3JhcHBlcg=='),
        fromB64('YVtocmVmKj0iLy9hZHYuaW1hZHJlcC5jby5rci8iXQ=='),
        fromB64('aW5zLmZhc3R2aWV3LWFk'),
        '.revenue_unit_item.dable',
      ],
      listeAr: [
        fromB64('LmdlbWluaUxCMUFk'),
        '.right-and-left-sponsers',
        fromB64('YVtocmVmKj0iLmFmbGFtLmluZm8iXQ=='),
        fromB64('YVtocmVmKj0iYm9vcmFxLm9yZyJd'),
        fromB64('YVtocmVmKj0iZHViaXp6bGUuY29tL2FyLz91dG1fc291cmNlPSJd'),
      ],
      listeFr: [
        fromB64('YVtocmVmXj0iaHR0cDovL3Byb21vLnZhZG9yLmNvbS8iXQ=='),
        fromB64('I2FkY29udGFpbmVyX3JlY2hlcmNoZQ=='),
        fromB64('YVtocmVmKj0id2Vib3JhbWEuZnIvZmNnaS1iaW4vIl0='),
        '.site-pub-interstitiel',
        'div[id^="crt-"][data-criteo-id]',
      ],
      officialPolish: [
        '#ceneo-placeholder-ceneo-12',
        fromB64('W2hyZWZePSJodHRwczovL2FmZi5zZW5kaHViLnBsLyJd'),
        fromB64('YVtocmVmXj0iaHR0cDovL2Fkdm1hbmFnZXIudGVjaGZ1bi5wbC9yZWRpcmVjdC8iXQ=='),
        fromB64('YVtocmVmXj0iaHR0cDovL3d3dy50cml6ZXIucGwvP3V0bV9zb3VyY2UiXQ=='),
        fromB64('ZGl2I3NrYXBpZWNfYWQ='),
      ],
      ro: [
        fromB64('YVtocmVmXj0iLy9hZmZ0cmsuYWx0ZXgucm8vQ291bnRlci9DbGljayJd'),
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9ibGFja2ZyaWRheXNhbGVzLnJvL3Ryay9zaG9wLyJd'),
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9ldmVudC4ycGVyZm9ybWFudC5jb20vZXZlbnRzL2NsaWNrIl0='),
        fromB64('YVtocmVmXj0iaHR0cHM6Ly9sLnByb2ZpdHNoYXJlLnJvLyJd'),
        'a[href^="/url/"]',
      ],
      ruAd: [
        fromB64('YVtocmVmKj0iLy9mZWJyYXJlLnJ1LyJd'),
        fromB64('YVtocmVmKj0iLy91dGltZy5ydS8iXQ=='),
        fromB64('YVtocmVmKj0iOi8vY2hpa2lkaWtpLnJ1Il0='),
        '#pgeldiz',
        '.yandex-rtb-block',
      ],
      thaiAds: [
        'a[href*=macau-uta-popup]',
        fromB64('I2Fkcy1nb29nbGUtbWlkZGxlX3JlY3RhbmdsZS1ncm91cA=='),
        fromB64('LmFkczMwMHM='),
        '.bumq',
        '.img-kosana',
      ],
      webAnnoyancesUltralist: [
        '#mod-social-share-2',
        '#social-tools',
        fromB64('LmN0cGwtZnVsbGJhbm5lcg=='),
        '.zergnet-recommend',
        '.yt.btn-link.btn-md.btn',
      ],
    }
}

// from fingerprintjs repo
function forceShow(element: HTMLElement) {
    element.style.setProperty('visibility', 'hidden', 'important')
    element.style.setProperty('display', 'block', 'important')
}
// from fingerprintjs repo
async function getBlockedSelectors<T extends string>(selectors: readonly T[]): Promise<{ [K in T]?: true }> {
    const d = document
    const root = d.createElement('div')
    const elements = new Array<HTMLElement>(selectors.length)
    const blockedSelectors: { [K in T]?: true } = {} // Set() isn't used just in case somebody need older browser support
  
    forceShow(root)
  
    // First create all elements that can be blocked. If the DOM steps below are done in a single cycle,
    // browser will alternate tree modification and layout reading, that is very slow.
    for (let i = 0; i < selectors.length; ++i) {
      const element = selectorToElement(selectors[i])
      if (element.tagName === 'DIALOG') {
        ;(element as HTMLDialogElement).show()
      }
      const holder = d.createElement('div') // Protects from unwanted effects of `+` and `~` selectors of filters
      forceShow(holder)
      holder.appendChild(element)
      root.appendChild(holder)
      elements[i] = element
    }
  
    // document.body can be null while the page is loading
    while (!d.body) {
      await wait(50)
    }
    d.body.appendChild(root)
  
    await wait(0)
  
    try {
      // Then check which of the elements are blocked
      for (let i = 0; i < selectors.length; ++i) {
        if (!elements[i].offsetParent) {
          blockedSelectors[selectors[i]] = true
        }
      }
    } finally {
      // Then remove the elements
      root.parentNode?.removeChild(root)
    }
  
    return blockedSelectors
}

// from fingerprintjs repo
function selectorToElement(selector: string): HTMLElement {
    const [tag, attributes] = parseSimpleCssSelector(selector)
    const element = document.createElement(tag ?? 'div')
    for (const name of Object.keys(attributes)) {
      const value = attributes[name].join(' ')
      // Changing the `style` attribute can cause a CSP error, therefore we change the `style.cssText` property.
      // https://github.com/fingerprintjs/fingerprintjs/issues/733
      if (name === 'style') {
        addStyleString(element.style, value)
      } else {
        element.setAttribute(name, value)
      }
    }
    return element
  }
// from fingerprintjs repo
  function addStyleString(style: CSSStyleDeclaration, source: string): void {
    // We don't use `style.cssText` because browsers must block it when no `unsafe-eval` CSP is presented: https://csplite.com/csp145/#w3c_note
    // Even though the browsers ignore this standard, we don't use `cssText` just in case.
    for (const property of source.split(';')) {
      const match = /^\s*([\w-]+)\s*:\s*(.+?)(\s*!([\w-]+))?\s*$/.exec(property)
      if (match) {
        const [, name, value, , priority] = match
        style.setProperty(name, value, priority || '') // The last argument can't be undefined in IE11
      }
    }
  }
// from fingerprintjs repo
  function parseSimpleCssSelector(selector: string,): [tag: string | undefined, attributes: Record<string, string[]>] {
    const errorMessage = `Unexpected syntax '${selector}'`
    const tagMatch = /^\s*([a-z-]*)(.*)$/i.exec(selector) as RegExpExecArray
    const tag = tagMatch[1] || undefined
    const attributes: Record<string, string[]> = {}
    const partsRegex = /([.:#][\w-]+|\[.+?\])/gi
  
    const addAttribute = (name: string, value: string) => {
      attributes[name] = attributes[name] || []
      attributes[name].push(value)
    }
  
    for (;;) {
      const match = partsRegex.exec(tagMatch[2])
      if (!match) {
        break
      }
      const part = match[0]
      switch (part[0]) {
        case '.':
          addAttribute('class', part.slice(1))
          break
        case '#':
          addAttribute('id', part.slice(1))
          break
        case '[': {
          const attributeMatch = /^\[([\w-]+)([~|^$*]?=("(.*?)"|([\w-]+)))?(\s+[is])?\]$/.exec(part)
          if (attributeMatch) {
            addAttribute(attributeMatch[1], attributeMatch[4] ?? attributeMatch[5] ?? '')
          } else {
            throw new Error(errorMessage)
          }
          break
        }
        default:
          throw new Error(errorMessage)
      }
    }
  
    return [tag, attributes]
  }

// from fingerprintjs repo
  export function wait<T = void>(durationMs: number, resolveWith?: T): Promise<T> {
    return new Promise((resolve) => setTimeout(resolve, durationMs, resolveWith))
  }
  