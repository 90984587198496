export class Detector {
    baseFonts: String[]
    defaultWidth: {[key: string]: number} = {}
    defaultHeight: {[key: string]: number} = {}
    span: HTMLElement = document.createElement('span')
    body: HTMLElement = document.getElementsByTagName('body')[0]

    constructor () {
        this.baseFonts = ['monospace', 'sans-serif', 'serif']

        // we use m or w because these two characters take up the maximum width.
        // And we use a LLi so that the same matching fonts can get separated
        const testString = 'mmmmmmmmmmlli'

        // we test using 72px font size, we may use any size. I guess larger the better.
        const testSize = '72px'

        // create a SPAN in the document to get the width of the text we use to test
        this.span.style.fontSize = testSize
        this.span.innerHTML = testString
        this.baseFonts.forEach((fontName) => {
            this.span.style.fontFamily = fontName
            this.body.appendChild(this.span)
            this.defaultWidth[fontName] = this.span.offsetWidth // width for the default font
            this.defaultHeight[fontName] = this.span.offsetHeight // height for the defualt font
            this.body.removeChild(this.span)
        })
    }

    // a font will be compared against all the three default fonts.
    // and if it doesn't match all 3 then that font is not available.

    detect (font: String): boolean {
        let detected = false
        this.baseFonts.forEach((fontName) => {
            this.span.style.fontFamily = String(font) + ',' + String(fontName) // name of the font along with the base font for fallback.
            this.body.appendChild(this.span)
            const matched = (this.span.offsetWidth !== this.defaultWidth[fontName] || this.span.offsetHeight !== this.defaultHeight[fontName])
            this.body.removeChild(this.span)
            detected = detected || matched
        })

        return detected
    }
}
